:root {
  --p-color: #7e3adb;
  --s-color: #130944;
}

.subtitle {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: var(--p-color);
}

.title {
  letter-spacing: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  margin: 0;
  padding: 0;
  border: 0;
}
